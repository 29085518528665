//
// Forms.scss
//


// checkbox input right

.form-check-right{
    padding-left: 0;
    display: inline-block;
    padding-right: 1.25rem;
    .form-check-input{
      right: 0;
      margin-left: 0;
    }
  
    .form-check-label{
      display: block;
    }
  }
  
  
  .custom-control-right{
    padding-left: 0;
    padding-right: 1.5rem;  
    display: inline-block;
    .custom-control-label{
      display: inline-block;
      &:before, &:after{
        left: auto;
        right: -1.5rem;
      }
    }
  
    .custom-control-input{
      left: auto;
    }
}


.custom-control-label{
  cursor: pointer;
}

.form-section {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 20px;
  position: relative; /* Ensure relative positioning for absolute positioning of section-name */
}

.section-name {
  position: absolute;
  top: -10px; /* Adjust top positioning to center the section name along the border */
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  padding: 0 10px;
  font-weight: bold;
}

.section-content {
  padding: 20px; /* Example padding for section content */
}

.radio-options {
  display: flex;       /* Use flexbox for layout */
  justify-content: flex-start; /* Adjust alignment as needed */
  align-items: center; /* Align items vertically centered */
}
.radio-option {
  align-items: center; /* Align items vertically centered */
}

.form-check label {
  margin-right: 20px;
}
