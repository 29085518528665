//
// authentication.scss
//

.auth-body-bg {
  background-color: $card-bg;
}

.authentication-bg {
  height: 100vh;
  background-size: cover;
  background-position: center;
  .bg-overlay {
    background-color: #292626;
  }

  @media (max-width: 991px) {
    display: none;
  }
}

.authentication-page-content {
  height: 100vh;
  display: flex;
}

.auth-form-group-custom {
  position: relative;
  .form-control {
    height: 60px;
    padding-top: 28px;
    padding-left: 60px;
  }

  label {
    position: absolute;
    top: 7px;
    left: 60px;
  }

  .auti-custom-input-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 19px;
    font-size: 24px;
    color: $primary;
  }
}

// authentication home icon
.home-btn {
  position: absolute;
  top: 15px;
  right: 25px;
  z-index: 9;
}

.shipping-address {
  box-shadow: none;
  &.active {
    border-color: $primary !important;
  }
}

.text-error {
  font-size: 120px;
  @media (max-width: 575.98px) {
    font-size: 86px;
  }
}

.error-text {
  color: $danger;
  position: relative;

  .error-img {
    position: absolute;
    width: 120px;
    left: -15px;
    right: 0;
    bottom: 47px;

    @media (max-width: 575.98px) {
      width: 86px;
      left: -12px;
      bottom: 38px;
    }
  }
}

.thumbnail {
  position : relative
}

.thumbnail:hover img{
  opacity: 0.5;
}

.thumbnail:hover .deleteBtn{
  display: block;
}
.deleteBtn{
  top: 2%;
  left: 80%;
  position: absolute;
  display: none;
}
